<template>
    <div>
   <v-container fluid>
        <v-card class="add_card mb-12" flat color="#cac0c057" height="580px">
            <v-layout row wrap>
                <v-flex pa-5>
                    <input type="file" style="display: none" ref="image" accept="image/*" @change="onFilePicked">
                </v-flex>
            </v-layout>
            <h3 pr-3 style="text-align:center">رفع صور المركز</h3>
            <span>{{img_name}}</span>
         
                <v-row row wrap v-if="show" align="center" pr-5 style="    padding-right: 88px;">
             
                    <v-flex xs6 md3 sm3 v-for="(img ,index) in images" pa-2 :key="img" pt-5>
                        <v-card height="190" width="190" class="card_img">

                            <v-img :src="img" height="190" width="190" class="card_img" v-if="img!='a'">
                                <v-btn icon="" style="padding-right: 24px;position:relative;left: 46px;"
                                    @click="delete_img(editedItem.images[index].id,index)">
                                    <v-icon color="#fff">fas fa-window-close</v-icon>
                                </v-btn>
                            </v-img>
                            <v-btn icon v-else @click='pickFile(index)'>
                                <v-icon style="margin:0px" size="40">fas fa-plus-square</v-icon>
                            </v-btn>
                        </v-card>
                    </v-flex>
             
            </v-row>
       


        </v-card>
        <v-layout row wrap pr-4>
            <v-btn width="150px" color="red" @click="close()" pr-3 mr-r ml-3
                style="color:#fff;padding-right:5px;    margin-left: 10px;">
                <i class="fas fa-chevron-right" style=""></i>

                <span style="padding-right:6px;">خروج</span>


            </v-btn>




        </v-layout>
     </v-container>
    </div>
</template>


<script>
    const swal = require('sweetalert2')
    const axios = require('axios');
    export default {



        data() {
            return {
                show: false,
                img_cliced: -1,
                images: ['a', 'a', 'a', 'a','a', 'a', 'a', 'a'],
                editedItem: {
                    images: [
                        {
                            "image_url": ''
                        },
                        {
                            "image_url": ''
                        },
                        {
                            "image_url": ''
                        },
                        {
                            "image_url": ''
                        },


                        {
                            "image_url": ''
                        },
                        {
                            "image_url": ''
                        },
                        {
                            "image_url": ''
                        },
                        {
                            "image_url": ''
                        }
                    ],



                },
                img_name: '',
                owner_notes: '',
                loding_cancel: false,
                loding_accept: false,

            }
        },

        mounted() {


        },

        created() {
            this.getItemDetails();
        },

        methods: {

            close() {

                 this.$router.push({
                        name: "additem"
                    })

                //alert(this.images[3]);
                // const swal = require('sweetalert2')
                // if (this.images[0] == 'a' || this.images[1] == 'a' || this.images[2] == 'a' || this.images[3] == 'a')


                // {
                //     return this.$swal('تحذير', "  يجب اختيار ٤ صور ", 'warning');
                // } else {
                //     this.$router.push({
                //         name: "additem"
                //     })
                // }
            },

            getItemDetails() {

                var item_id = parseInt(this.$route.params.id);

                var url = "/v2/items/owner/OwnerItemsById/" + item_id;
                this.$http({
                        method: 'get',
                        url: url,
                        headers: {

                        }
                    })

                    .then(response => {
                        this.editedItem = response.data.data;
                        this.show = true;
                        this.imgs = this.editedItem.images;
                        for (i = 0; i < 8; i++) {
                            if (this.editedItem.images[i].image_path != 'a') {

                                this.images[i] = this.Url + "/images/" + this.editedItem.images[i]
                                    .image_url;


                            }

                        }


                        if (this.editedItem.images.length < 8) {
                            var completImgArrayLength = 8 - this.editedItem.images.length;

                            for (var i = 0; i < completImgArrayLength; i++) {

                                this.editedItem.images.push({
                                    image_path: 'a'
                                });
                            }

                        }











                    })
            },






            delete_img(img_id, index) {


                this.user_info = this.$cookies.get('admin_info');


                const Swal = require('sweetalert2');



                Swal.fire({
                    title: "هل انت متاكد من الحذف ؟",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'نعم',
                    cancelButtonText: 'خروج'

                }).then((result) => {
                    if (result.value) {
                        this.img_cliced = index;


                        this.editedItem.images[index].image_url = 'a';
                        this.img_name = index;
                        this.images[index] = 'a';

                        var url = "/v2/items/delete_image/" + img_id;
                        axios({
                            method: 'post',
                            url: url,
                            headers: {

                            }

                        }).then(response => {

                            response,
                            this.editedItem.images[index].image_url = 'a';
                            //not important
                            this.img_name = index;
                            this.images[index] = 'a';

                            swal.fire(
                                'تم الحذف بنجاح',
                                '',
                                'success'
                            )

                        }).catch(error => {
                            error
                            //     this.$swal('خطاء', "خطاء بالاتصال", 'error')
                        }).finally(d => {
                            d,

                            this.getitems();
                        });



                    }
                })




            },



            cancelImg() {
                this.images[0] = 'ab';
                this.img_name = 'ghjk'

            },


            pickFile(index) {

                this.img_cliced = index
                this.$refs.image.click()
            },

            onFilePicked(e) {

                const files = e.target.files
                if (files[0] !== undefined) {
                    this.imageName = files[0].name
                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }
                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {
                        this.imageUrl = fr.result
                        this.imageFile = files[0]
                        var imgg = {
                            images: [fr.result]
                        };
                        axios({
                                method: 'post',
                                url: 'v2/items/UploudeImage/' + parseInt(this.$route.params.id),
                                data: imgg
                            })
                            .then(response => {
                                response
                                //  const swal = require('sweetalert2')
                                const Swal = require('sweetalert2');
                                this.getItemDetails();
                                Swal.fire(
                                    'تم رفع الصوره بنجاح',
                                    '',
                                    'success'
                                )

                            })

                        this.images[this.img_cliced] = fr.result;
                        var a = this.img_cliced + 1;
                        this.img_name = "   صورة رقم " + a


                    })

                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''
                }
            },








        }


    }
</script>